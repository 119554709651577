/* Document
 * ========================================================================== */

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Backgrounds do not repeat by default (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Breaks words to prevent overflow in all browsers (opinionated).
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Remove the grey highlight on links in iOS (opinionated).
 * 6. Prevent adjustments of font size after orientation changes in iOS.
 */

:where(:root) {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  overflow-wrap: break-word; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -webkit-tap-highlight-color: transparent; /* 5 */
  -webkit-text-size-adjust: 100%; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

:where(body) {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

:where(h1) {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 */

:where(hr) {
  color: inherit; /* 1 */
  height: 0; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

:where(nav) :where(ol, ul) {
  list-style-type: none;
  padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */

:where(nav li)::before {
  content: "\200B";
  float: left;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Safari.
 */

:where(abbr[title]) {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

:where(b, strong) {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

:where(small) {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

:where(iframe) {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

:where(svg:not([fill])) {
  fill: currentColor;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

:where(table) {
  border-collapse: collapse; /* 1 */
  border-color: inherit; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

:where(button, input, select) {
  margin: 0;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

:where(progress) {
  vertical-align: baseline;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 3. Change the resize direction in all browsers (opinionated).
 */

:where(textarea) {
  margin: 0; /* 1 */
  resize: vertical; /* 3 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

:where([type="search" i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct styles in Safari.
 */

:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/*
 * Add the correct display in Safari.
 */

:where(details > summary:first-of-type) {
  display: list-item;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

:where([aria-busy="true" i]) {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

:where([aria-controls]) {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */

:where(button, input, select, textarea) {
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  padding: 0.25em 0.375em; /* 1 */
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(select) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E") no-repeat right center / 1em;
  border-radius: 0;
  padding-right: 1em;
}

/**
 * Don't show the arrow for multiple choice selects
 */

:where(select[multiple]) {
  background-image: none;
}

/**
 * Remove the border and padding in all browsers (opinionated).
 */

:where([type="color" i], [type="range" i]) {
  border-width: 0;
  padding: 0;
}

/**
 * Use the default user interface font in all browsers (opinionated).
 */

html {
  font-family:
    system-ui,
    /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ "Segoe UI",
    /* Android 4+ */ "Roboto",
    /* Ubuntu 10.10+ */ "Ubuntu",
    /* Gnome 3+ */ "Cantarell",
    /* KDE Plasma 5+ */ "Noto Sans",
    /* fallback */ sans-serif,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji";
}

/**
 * Use the default monospace user interface font in all browsers (opinionated).
 */

code,
kbd,
samp,
pre {
  font-family:
    ui-monospace,
    /* macOS 10.10+ */ "Menlo",
    /* Windows 6+ */ "Consolas",
    /* Android 4+ */ "Roboto Mono",
    /* Ubuntu 10.10+ */ "Ubuntu Monospace",
    /* KDE Plasma 5+ */ "Noto Mono",
    /* KDE Plasma 4+ */ "Oxygen Mono",
    /* Linux/OpenOffice fallback */ "Liberation Mono",
    /* fallback */ monospace,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji";
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/20abfcf01c9859f3-s.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/e7f46b00158835a6-s.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/a07dfc6a9971a0e8-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/8e2b04e7f3558698-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/e4af0746e818fa56-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/ab5a8073f8fa1550-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/2bd918e3670ac349-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/9ad279f7fd91962c-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/17497d2160c5dd7e-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/28bc1e907842b91a-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/f696d8c301fb63f3-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: '__Zermatt_a49b80';
src: url(/_next/static/media/085591a670afb075-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__Zermatt_Fallback_a49b80';src: local("Arial");ascent-override: 80.00%;descent-override: 24.08%;line-gap-override: 0.00%;size-adjust: 114.75%
}.__className_a49b80 {font-family: '__Zermatt_a49b80', '__Zermatt_Fallback_a49b80', sans-serif
}.__variable_a49b80 {--font-Zermatt: '__Zermatt_a49b80', '__Zermatt_Fallback_a49b80', sans-serif
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/8cb610177d9e87ea-s.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/5f5eeda5a1845e6b-s.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/296a1c1e31606d00-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/ec9b04f6aa35fc5b-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/5e2d2391eb9299d6-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/754275a0a5d04e6a-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/d04bb4faf8227b87-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/b442de4edf3c3b7f-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/05191fe2e9f5a72d-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/caa86fd1b09a007e-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/f658c1822e2c9aca-s.woff2) format('woff2');
font-display: swap;
font-weight: 550;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/974f81a3e93e289d-s.woff2) format('woff2');
font-display: swap;
font-weight: 550;
font-style: italic;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/b5fd304b33a2db56-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__Poppins_f30ff4';
src: url(/_next/static/media/a633f758aeebceda-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}@font-face {font-family: '__Poppins_Fallback_f30ff4';src: local("Arial");ascent-override: 94.21%;descent-override: 52.31%;line-gap-override: 0.00%;size-adjust: 113.04%
}.__className_f30ff4 {font-family: '__Poppins_f30ff4', '__Poppins_Fallback_f30ff4', sans-serif
}.__variable_f30ff4 {--font-Poppins: '__Poppins_f30ff4', '__Poppins_Fallback_f30ff4', sans-serif
}

@font-face {
font-family: '__BlackDelights_af9d8a';
src: url(/_next/static/media/6e508c44935c0d9f-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__BlackDelights_Fallback_af9d8a';src: local("Arial");ascent-override: 99.12%;descent-override: 24.32%;line-gap-override: 28.08%;size-adjust: 90.93%
}.__className_af9d8a {font-family: '__BlackDelights_af9d8a', '__BlackDelights_Fallback_af9d8a', serif;font-weight: normal;font-style: normal
}.__variable_af9d8a {--font-BlackDelights: '__BlackDelights_af9d8a', '__BlackDelights_Fallback_af9d8a', serif
}

@font-face {
font-family: '__TTFirsNeue_1f089e';
src: url(/_next/static/media/17dc9dfa2ceeda11-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__TTFirsNeue_1f089e';
src: url(/_next/static/media/2923582418a09f7b-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__TTFirsNeue_Fallback_1f089e';src: local("Arial");ascent-override: 93.64%;descent-override: 27.16%;line-gap-override: 0.00%;size-adjust: 106.79%
}.__className_1f089e {font-family: '__TTFirsNeue_1f089e', '__TTFirsNeue_Fallback_1f089e', sans-serif
}.__variable_1f089e {--font-TTFirsNeue: '__TTFirsNeue_1f089e', '__TTFirsNeue_Fallback_1f089e', sans-serif
}

@font-face {
font-family: '__Chaney_d7e14d';
src: url(/_next/static/media/7da25b47e158def7-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
}@font-face {font-family: '__Chaney_Fallback_d7e14d';src: local("Arial");ascent-override: 43.21%;descent-override: 12.83%;line-gap-override: 0.00%;size-adjust: 223.77%
}.__className_d7e14d {font-family: '__Chaney_d7e14d', '__Chaney_Fallback_d7e14d', sans-serif;font-weight: normal
}.__variable_d7e14d {--font-Chaney: '__Chaney_d7e14d', '__Chaney_Fallback_d7e14d', sans-serif
}

@font-face {
font-family: '__HelveticaNeue_87d4ed';
src: url(/_next/static/media/f76ea79a6cb42171-s.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__HelveticaNeue_87d4ed';
src: url(/_next/static/media/69bbcba0883ca025-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__HelveticaNeue_87d4ed';
src: url(/_next/static/media/2e202306008026fe-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__HelveticaNeue_87d4ed';
src: url(/_next/static/media/edfa245493c4c854-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__HelveticaNeue_Fallback_87d4ed';src: local("Arial");ascent-override: 70.43%;descent-override: 28.21%;line-gap-override: 19.73%;size-adjust: 101.37%
}.__className_87d4ed {font-family: '__HelveticaNeue_87d4ed', '__HelveticaNeue_Fallback_87d4ed', Helvetica Neue
}.__variable_87d4ed {--font-HelveticaNeue: '__HelveticaNeue_87d4ed', '__HelveticaNeue_Fallback_87d4ed', Helvetica Neue
}

@font-face {
font-family: '__Georgia_317e52';
src: url(/_next/static/media/c06e297b84665300-s.woff2) format('woff2');
font-display: swap;
font-weight: normal;
}@font-face {font-family: '__Georgia_Fallback_317e52';src: local("Arial");ascent-override: 92.30%;descent-override: 22.07%;line-gap-override: 0.00%;size-adjust: 99.35%
}.__className_317e52 {font-family: '__Georgia_317e52', '__Georgia_Fallback_317e52', sans-serif;font-weight: normal
}.__variable_317e52 {--font-Georgia: '__Georgia_317e52', '__Georgia_Fallback_317e52', sans-serif
}

@font-face {
font-family: '__Floreal_ebf4e7';
src: url(/_next/static/media/18529a4e0fe6f5b8-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Floreal_ebf4e7';
src: url(/_next/static/media/f4d0d8b3c9b5cbca-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__Floreal_Fallback_ebf4e7';src: local("Arial");ascent-override: 90.27%;descent-override: 29.21%;line-gap-override: 0.00%;size-adjust: 94.82%
}.__className_ebf4e7 {font-family: '__Floreal_ebf4e7', '__Floreal_Fallback_ebf4e7', serif
}.__variable_ebf4e7 {--font-Floreal: '__Floreal_ebf4e7', '__Floreal_Fallback_ebf4e7', serif
}

/* latin */
@font-face {
  font-family: '__Orbitron_ae62cb';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/04ff47e1ca568747-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Orbitron_Fallback_ae62cb';src: local("Arial");ascent-override: 81.50%;descent-override: 19.59%;line-gap-override: 0.00%;size-adjust: 124.05%
}.__className_ae62cb {font-family: '__Orbitron_ae62cb', '__Orbitron_Fallback_ae62cb', serif;font-style: normal
}

/* arabic */
@font-face {
  font-family: '__Rubik_684a77';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_684a77';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_684a77';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_684a77';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_684a77';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_684a77';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Rubik_Fallback_684a77';src: local("Arial");ascent-override: 89.06%;descent-override: 23.81%;line-gap-override: 0.00%;size-adjust: 104.98%
}.__className_684a77 {font-family: '__Rubik_684a77', '__Rubik_Fallback_684a77', serif;font-style: normal
}

